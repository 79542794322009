<template>
  <div class="py-4">
    <main>
      <div class="max-w-6xl mx-auto sm:px-6 lg:px-8">
        <div class="mx-auto mt-6">
          <div class="px-6 py-10 bg-white rounded-lg shadow sm:py-16 sm:px-12 lg:py-8 lg:px-14 lg:flex lg:items-center">
            <div class="lg:w-0 lg:flex-1">
              <h2 class="text-3xl font-extrabold tracking-tight text-black">Search by Branch</h2>
              <p class="mt-1 text-black">Locate parcels within a branch</p>
            </div>
            <div class="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
              <div class="sm:flex">
                <label for="branchId" class="sr-only">FAD Code</label>
                <input id="branchId" v-model="branchId" name="branchId" type="text" required class="w-full px-4 py-3 placeholder-black border border-gray-300 rounded-md bg-gray-50" placeholder="Enter Branch ID">
                <button @click="navigateToBranchPage()" type="submit" class="flex items-center justify-center w-full px-5 py-3 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-auto mt-6">
          <div class="px-6 py-10 bg-white rounded-lg shadow sm:py-16 sm:px-12 lg:py-8 lg:px-14 lg:flex lg:items-center">
            <div class="lg:w-0 lg:flex-1">
              <h2 class="text-3xl font-extrabold tracking-tight text-black">Find Parcel</h2>
              <p class="mt-1 text-black">Find details specific to a parcel</p>
            </div>
            <div class="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:flex-1">
              <div class="sm:flex">
                <label for="carrierId" class="sr-only">Carrier ID</label>
                <select v-model="carrierId" id="carrierId" name="carrierId" class="px-4 py-3 pr-4 mr-4 placeholder-black border border-gray-300 rounded-md lg:max-w-full bg-gray-50">
                  <option value="Carrier">Carrier</option>
                  <option value="dpd">DPD</option>
                  <option value="RM">Royal Mail</option>
                  <option value="dpdni">DPD NI</option>
                  <option value="dhl">DHL</option>
                  <option value="evri">Evri</option>
                </select>
                <label for="trackingId" class="sr-only">Tracking ID</label>
                <input id="trackingId" v-model="trackingId" type="text" required class="px-4 py-3 placeholder-black border border-gray-300 rounded-md w-26 bg-gray-50" placeholder="Enter Tracking ID">
                <button @click="navigateToTrackingPage()" type="submit" class="flex items-center justify-center w-full px-5 py-3 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-auto mt-6">
          <div class="px-6 py-10 bg-white rounded-lg shadow sm:py-16 sm:px-12 lg:py-8 lg:px-14 lg:flex lg:items-center">
            <div class="lg:w-0 lg:flex-1">
              <h2 class="text-3xl font-extrabold tracking-tight text-black">Find Bulk Parcels</h2>
              <p class="mt-1 text-black">Find details specific to multiple parcels</p>
              <div class="mt-1 flex">
                <p class="text-black mr-2">Search By:</p>
                <div class="form-check form-check-inline">
                  <input type="radio" name="searchModeRadio"
                  class="form-check-input form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-pol-red-600 checked:border-pol-red-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                  value="inputBox" id="inputBoxRdBtn"
                  @change="radioBtnOptChanged()"
                  v-model="searchMode">
                  <label class="form-check-label inline-block text-gray-800 text-sm px-1" for="inputBoxRdBtn">
                    Input Tracking Ids
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input type="radio" name="searchModeRadio"
                  class="form-check-input form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-pol-red-600 checked:border-pol-red-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                  value="fileUpload" id="fileUploadRdBtn"
                  @change="radioBtnOptChanged()"
                  v-model="searchMode" checked>
                  <label class="form-check-label inline-block text-gray-800 text-sm px-1" for="fileUploadRdBtn">
                    Upload CSV file
                  </label>
                </div>
              </div>
            </div>
            <div class="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:flex-auto">
              <div class="sm:flex sm:w-50">
                <label for="bulkCarrierId" class="sr-only">Carrier ID</label>
                <select v-model="bulkCarrierId" id="bulkCarrierId" name="bulkCarrierId" class="px-4 py-3 pr-4 mr-4 placeholder-black border border-gray-300 rounded-md lg:max-w-full bg-gray-50">
                  <option value="Carrier">Carrier</option>
                  <option value="dpd">DPD</option>
                  <option value="RM">Royal Mail</option>
                  <option value="dpdni">DPD NI</option>
                  <option value="dhl">DHL</option>
                  <option value="evri">Evri</option>
                </select>
                <div v-if="searchMode == 'fileUpload'">
                  <label class="block" for="trackIdsUpload">
                    <input type="file" id="trackIdsUpload"
                      ref="file" @change="selectFile" accept=".csv"
                      class="form-control block w-full px-2 py-1
                      text-sm font-normal text-gray-700 bg-white bg-clip-padding
                      border border-solid border-gray-300 rounded transition ease-in-out
                      m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"/>
                  </label>
                </div>
                <div v-if="searchMode == 'inputBox'">
                  <label for="trackingIds" class="sr-only">Tracking ID</label>
                  <div class="flex relative">
                    <input id="trackingIds" name="trackingIds" v-model="trackingInputs"
                    @keyup.enter="addTid(); $emit('inserted')"
                    type="text" class="px-4 py-3 placeholder-black border border-gray-300 rounded-md w-26 bg-gray-50" placeholder="Enter Tracking IDs">
                    <button type="button" @click="addTid(); $emit('inserted')" class="absolute text-xs right-2 top-2 px-2 py-1 mr-1 flex items-center justify-center w-full text-base font-xs text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                    :disabled="isMaxLimitReached()">
                      +
                    </button>
                  </div>
                </div>
                <button type="submit" class="flex items-center justify-center w-full px-5 py-3 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0" :disabled="isBulkSearchBtnDisabled()" @click="navigateToBulkTrackingPage()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg> Search
                </button>
              </div>
              <div class="sm:flex sm:w-50" id="chipsTrackingId">
                <ul class="flex flex-wrap justify-center space-x-2">
                  <li v-for="tId in inputResult" :key="tId">
                    <div class="mt-1" v-if="inputResult.length > 0">
                      <label>
                        <span class="px-4 py-2 rounded-full text-gray-500 border border-gray-300 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                          {{tId}}
                          <button @click="removeTid(tId)" class="bg-transparent hover focus:outline-none">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                              class="w-3 ml-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                              <path fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                              </path>
                            </svg>
                          </button>
                        </span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-auto mt-6">
          <div class="px-6 py-10 bg-white rounded-lg shadow sm:py-16 sm:px-12 lg:py-8 lg:px-14 lg:flex lg:items-center">
            <div class="lg:w-0 lg:flex-1">
              <h2 class="text-3xl font-extrabold tracking-tight text-black">Search by Area Manager</h2>
              <p class="mt-1 text-black">Locate parcels within Area Manager</p>
            </div>
            <div class="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
              <div class="sm:flex">
                <label for="Area Manager" class="sr-only">Area Manager</label>
                <input id="areaManager" v-model="areaManager" name="areaManager" type="text" required class="w-full px-4 py-3 placeholder-black border border-gray-300 rounded-md bg-gray-50" placeholder="Enter Area Manager Name">
                <button @click="navigateToAreaManagerPage()" type="submit" class="flex items-center justify-center w-full px-5 py-3 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-auto mt-6">
          <div class="px-6 py-10 bg-white rounded-lg shadow sm:py-16 sm:px-12 lg:py-8 lg:px-14 lg:flex lg:items-center">
            <div class="lg:w-0 lg:flex-1">
              <h2 class="text-3xl font-extrabold tracking-tight text-black">Search by Branch Type</h2>
              <p class="mt-1 text-black">Locate parcels within Branch Type</p>
            </div>
            <div class="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
              <div class="sm:flex">
                <label for="BranchType" class="sr-only">BranchType</label>
                <input id="branchType" v-model="branchType" name="branchType" type="text" required class="w-full px-4 py-3 placeholder-black border border-gray-300 rounded-md bg-gray-50" placeholder="Enter Branch Type">
                <button @click="navigateToBranchTypePage()" type="submit" class="flex items-center justify-center w-full px-5 py-3 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-auto mt-6">
          <div class="px-6 py-10 bg-white rounded-lg shadow sm:py-16 sm:px-12 lg:py-8 lg:px-14 lg:flex lg:items-center">
            <div class="lg:w-0 lg:flex-1">
              <h2 class="text-3xl font-extrabold tracking-tight text-black">Find By Status</h2>
              <p class="mt-1 text-black">Find the last week's parcels with your specified status </p>
            </div>
            <div class="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:flex-1">
              <div class="sm:flex">
                <label for="statusCarrierId" class="sr-only">Carrier ID</label>
                <select v-model="statusCarrierId" id="statusCarrierId" name="statusCarrierId" class="px-4 py-3 pr-4 mr-4 placeholder-black border border-gray-300 rounded-md lg:max-w-full bg-gray-50">
                  <option value="Carrier">Carrier</option>
                  <option value="dpd">DPD</option>
                  <option value="RM">Royal Mail</option>
                  <option value="dpdni">DPD NI</option>
                  <option value="PF">Parcel Force</option>
                  <option value="dhl">DHL</option>
                  <option value="evri">Evri</option>
                </select>
                <label for="statusStatus" class="sr-only">Status</label>
                <select v-model="statusStatus" id="statusStatus" name="statusStatus" class="w-full px-4 py-3 placeholder-black border border-gray-300 rounded-md bg-gray-50">
                  <option value="Status">Status</option>
                  <option v-for="status in parcelStatuses" :key="status" :value="status">
                    {{status}}
                  </option>
                </select>
                <button @click="navigateToStatusPage()" type="submit" class="flex items-center justify-center w-full px-5 py-3 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>

const SearchMode = Object.freeze({ FILEUPLOAD: 'fileUpload', INPUTBOX: 'inputBox' })
export default {
  name: 'Home',
  data () {
    return {
      trackingId: null,
      carrierId: 'Carrier',
      branchId: null,
      bulkCarrierId: 'Carrier',
      selectedFiles: null,
      currentFile: null,
      content: null,
      uploadResult: [],
      inputResult: [],
      statusStatus: 'Status',
      statusCarrierId: 'Carrier',
      searchMode: SearchMode.FILEUPLOAD,
      trackingInputs: '',
      trackingIdSearchlimit: 20,
      areaManager: null,
      branchType: null,
      parcelStatuses: [
        'BRANCH_UNRECOGNISED'
      ]
    }
  },
  methods: {
    navigateToBranchPage (event) {
      if (!this.branchId) {
        alert('Please enter branch id')
        return
      }
      if (this.branchId.length < 6) {
        alert('Please enter a 6 or 7 digit FAD code')
        return
      }

      this.$router.push({ name: 'branch', params: { branchId: this.branchId } })
    },
    navigateToStatusPage (event) {
      if (this.statusCarrierId === 'Carrier') {
        alert('Please select a carrier id')
        return
      }
      if (this.statusStatus === 'Status') {
        alert('Please select a parcel status')
        return
      }

      this.$router.push({ name: 'status', params: { status: this.statusStatus, carrierId: this.statusCarrierId } })
    },
    navigateToAreaManagerPage () {
      if (!this.areaManager) {
        alert('Please enter Area Manager Name')
        return
      }

      this.$router.push({ name: 'areaManager', params: { areaManager: this.areaManager } })
    },
    navigateToBranchTypePage () {
      if (!this.branchType) {
        alert('Please enter Branch Type')
        return
      }

      this.$router.push({ name: 'branchType', params: { branchType: this.branchType } })
    },
    navigateToTrackingPage () {
      if (!this.trackingId) {
        alert('Please enter tracking id')
        return
      }

      if (this.carrierId === 'Carrier') {
        alert('Please select carrier')
        return
      }

      this.$router.push({ name: 'track', params: { carrierId: this.carrierId, trackingId: this.trackingId } })
    },
    selectFile () {
      this.selectedFiles = this.$refs.file.files
      this.currentFile = this.selectedFiles.item(0)
      const reader = new FileReader()
      if (this.currentFile.size > 10 * 1024 * 1024) {
        alert('Please upload file of size 10 MB or smaller')
        return
      }
      if (!this.currentFile.name.includes('.csv')) {
        alert('Please upload csv file')
        return
      }
      reader.readAsBinaryString(this.currentFile)
      reader.onload = (res) => {
        const fileContent = res.target.result.split('\r' + '\n')
        this.content = fileContent.filter((c, i) => i !== 0 && c !== '')
        const results = [...new Set(this.content.slice(0, this.trackingIdSearchlimit + 1))]
        // console.log(results)
        this.uploadResult = JSON.stringify(results)
      }
      reader.onerror = (err) => console.log(err)
    },
    radioBtnOptChanged () {
      this.uploadResult = []
      this.inputResult = []
    },
    navigateToBulkTrackingPage () {
      if (this.bulkCarrierId === 'Carrier') {
        alert('Please select carrier')
        return
      }

      if (this.searchMode === SearchMode.FILEUPLOAD) {
        this.selectedFiles = undefined
        this.$store.commit('transferJsonData', {
          transferredData: this.uploadResult
        })
      } else if (this.searchMode === SearchMode.INPUTBOX) {
        if (this.trackingInputs === '' && this.inputResult.length === 0) {
          alert('Please enter one or more tracking ids')
          return
        }
        this.$store.commit('transferJsonData', {
          transferredData: JSON.stringify(this.inputResult)
        })
      }

      this.$router.push({ name: 'bulk-track', params: { carrierId: this.bulkCarrierId } })
    },
    isMaxLimitReached () {
      return this.inputResult.length >= this.trackingIdSearchlimit
    },
    addTid () {
      if (this.isMaxLimitReached()) {
        alert('Max 20 Tracking Ids can be entered for search')
        return
      }
      if (this.inputResult.includes(this.trackingInputs)) {
        alert('Tracking Id already added')
        return
      }
      if (this.trackingInputs !== '') {
        this.inputResult.push(this.trackingInputs)
        this.trackingInputs = ''
      }
    },
    removeTid (tId) {
      const idx = this.inputResult.indexOf(tId)
      this.inputResult = this.inputResult.filter(e => e !== this.inputResult[idx])
    },
    isBulkSearchBtnDisabled () {
      return this.searchMode === 'fileUpload' ? (!this.selectedFiles) : (this.inputResult.length === 0)
    }
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      }
    }
  }
}
</script>
